/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    a: "a",
    h2: "h2",
    div: "div",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The Javascript SDK will ", React.createElement(_components.code, null, "postMessage"), " ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/MessageEvent"
  }, "MessageEvent"), " events to the content window. This allows you to integrate your own\nhandlers for these events."), "\n", React.createElement(_components.h2, {
    id: "subscribing-to-events",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#subscribing-to-events",
    "aria-label": "subscribing to events permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Subscribing to events"), "\n", React.createElement(_components.p, null, "You can subscribe to these events like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "function receiveMessage(event) {\n    const msg = event?.data\n    if (msg?.type === 'signatu:show-banner') {\n        // Ensure the DIV covers the full screen\n    }\n}\n\nwindow.addEventListener('message', receiveMessage, false)\n")), "\n", React.createElement(_components.h2, {
    id: "supported-events",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#supported-events",
    "aria-label": "supported events permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Supported Events"), "\n", React.createElement(_components.p, null, "| Event                 | Description                                                                                                                                                        |\n| --------------------- | ------------------------------------------------------------------------------------------------------------------------------------------------------------------ |\n| ", React.createElement(_components.code, null, "signatu:consent"), "     | Invoked whenever a new Consent event is registered. The ", React.createElement(_components.code, null, "consent"), " payload will contain the actual consent event.                                                   |\n| ", React.createElement(_components.code, null, "signatu:show-banner"), " | Show the banner - i.e., ensure that the ", React.createElement(_components.code, null, "div"), " containing the banner is on top and covers the screen. If you are using the Shim this will be handled automatically. |\n| ", React.createElement(_components.code, null, "signatu:hide-banner"), " | Hide the banner - i.e., ensure that the ", React.createElement(_components.code, null, "div"), " containing the banner is hidden. If you are using the Shim this will be handled automatically.                       |"), "\n", React.createElement(_components.h2, {
    id: "example",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#example",
    "aria-label": "example permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Example"), "\n", React.createElement(_components.p, null, "The following example shows a ", React.createElement(_components.code, null, "MessageEvent"), " containing a Consent Event sent to the content window. ", React.createElement(_components.code, null, "consent"), " contains the full consent event (see ", React.createElement(_components.a, {
    href: "../../consent"
  }, "Consent Schema"), " for a complete description)."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n    \"bubbles\": false,\n    \"cancelBubble\": false,\n    \"defaultPrevented\": false,\n    /** ... */\n    \"data\": {\n        \"type\": \"signatu:consent\",\n        \"consent\": {\n            \"subject\": \"1666a664380aad0d6d4ed0cb3357be28637b3a25a18b9b3f1c49501e7835d73a\",\n            \"issuer\": \"https://signatu.com/api/v1/datacontrollers/23\",\n            \"target\": \"https://signatu.com/api/v1/policies/5\",\n            \"source\": \"https://signatu.com/app/tab/integrate\",\n            \"action\": true,\n            \"token\": \"TMCMB6\",\n            \"createdAt\": \"2017-02-24T08:45:34.398Z\"\n        }\n    }\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
